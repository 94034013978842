import WebServiceRequest from './WebServiceRequest'

class TradeCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class TradeUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class TradeGetMyTradesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/GetMyTrades')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class TradeGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class TradeDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/Delete')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class TradeGetActiveTradesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/GetActiveTrades')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class TradeGetLastTradesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/GetLastTrades')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class TradeHighTradesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Trade/HighTrades')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
export {
    TradeCreateRequest,
    TradeUpdateRequest,
    TradeGetMyTradesRequest,
    TradeGetAllRequest,
    TradeGetActiveTradesRequest,
    TradeDeleteRequest,
    TradeGetLastTradesRequest,
    TradeHighTradesRequest
};
<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="GetAllTrades"
  >
    <template #title> Trades List</template>
    <template #titleBtn>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="success"
          @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <search-input
          @enter="GetAllTrades"
          v-model="tradesListFilters.tradeId"
          inputId="tradeIdFilter"
          inputLabel="Trade ID"
          inputPlaceholder="Enter your desired Trade ID"
      ></search-input>
      <search-input
          @enter="GetAllTrades"
          v-model="tradesListFilters.market"
          inputId="fullNameFilter"
          inputLabel="Search By Market"
          inputPlaceholder="BTCUSDT"
      ></search-input>
      <b-form-group label="State" label-for="state">
        <v-select
            class="mr-md-2 w-auto"
            id="state"
            v-model="tradesListFilters.state"
            :options="perState"
            :clearable="false"
            :reduce="(val) => val.value"
        />
      </b-form-group>
      <b-form-group label="Count" label-for="count">
        <v-select
            class="mr-md-2 w-auto"
            id="count"
            v-model="tradesListFilters.count"
            :options="perPageOptions"
            :clearable="false"
        />
      </b-form-group>
      <b-form-group label="Fake Trades" label-for="fakeTrades">
        <v-select
            class="mr-md-2 w-auto"
            id="fakeTrades"
            v-model="tradesListFilters.isFake"
            :options="isFakeOptions"
            label="name"
            :reduce="name=> name.value"
        />
      </b-form-group>
    </template>
    <custom-table
        :pageNumber="tradesListFilters.pageNumber"
        :count="tradesListFilters.count"
        :totalCount="totalCount"
        :tableItems="trades"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
      <template #userInfo="data">
        <p>{{ data.item.userInfo.userName }}</p>
      </template>
      <template #createDate="data">
        {{ data.item.createDate.slice(0, 10) }}
        -
        {{ data.item.createDate.slice(11, 19) }}
      </template>
      <template #amount="data">
        <p>{{ numChecker(parseFloat(data.item.amount)) }} $</p>
      </template>
      <template #state="data">
        <b-badge :variant="tradesStateColors[data.item.state]">
          {{ perState[data.item.state].label }}
        </b-badge>
      </template>
      <template #profit="data">
        <span v-if="data.item.profit !== null" class="text-success">
          {{ numChecker(parseFloat(data.item.profit)) }} $
        </span>
        <span v-else-if="data.item.loss !== null" class="text-danger">
          {{ numChecker(parseFloat(data.item.loss)) }} $
        </span>
      </template>
      <template #type="data">
        <span>
          <feather-icon
              :icon="data.item.type == 1 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.type == 1 ? 'text-success' : 'text-danger'"
              size="20"
          />
        </span>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import {BBadge, BButton, BFormGroup} from "bootstrap-vue";
import vSelect from "vue-select";
import {TradeGetAllRequest} from "@/libs/Api/Trade";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from "file-saver";
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";

export default {
  title: "Trades List",
  name: "TradesList",
  data() {
    return {
      isLoading: false,
      tradesStateColors: {
        1: "secondary",
        2: "success",
        3: "danger",
      },
      tradesListFilters: {
        pageNumber: 1,
        count: 10,
        market: null,
        state: null,
        tradeId: null,
        isFake: null,
      },
      isFakeOptions: [
        {
          name: 'Show',
          value: true
        },
        {
          name: 'Hide',
          value: false
        },
      ],
      trades: null,
      totalCount: null,
      perPageOptions: [10, 20, 30, 40, 50],
      perState: [
        {label: "All", value: null},
        {label: "Pending", value: 1},
        {label: "Win", value: 2},
        {label: "Lose", value: 3},
      ],
      myTableColumns: [
        {
          key: "tradeId",
          label: "Trade id",
        },
        {
          key: "candleId",
          label: "Candle id",
        },
        {
          key: "market",
          label: "Currency",
        },
        {
          key: "userInfo",
          label: "User",
        },
        {
          key: "type",
          label: "Up / Down",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "state",
          label: "Result",
        },
        {
          key: "profit",
          label: "Profit",
        },
        {
          key: "createDate",
          label: "Create Date",
        },
      ],
    };
  },
  async created() {
    await this.GetAllTrades();
  },
  methods: {
    changePage(pageNumber) {
      this.tradesListFilters.pageNumber = pageNumber;
      this.GetAllTrades();
    },
    async GetAllTrades() {
      try {
        this.isLoading = true;
        let _this = this;
        let tradeGetAllRequest = new TradeGetAllRequest(_this);
        let data = {
          pageNumber: _this.currentPage,
          count: _this.perPage,
          market: _this.market,
          state: _this.state,
        };
        tradeGetAllRequest.setParams(_this.tradesListFilters);
        await tradeGetAllRequest.fetch(
            function (content) {
              _this.trades = content.trades;
              _this.totalCount = content.tradesCount;
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    numChecker(content) {
      if (content.toString().split(".")[1]?.length > 8) {
        return (content =
            content.toString().split(".")[0] +
            "." +
            content.toString().split(".")[1].substr(0, 8));
      } else return content;
    },
    async downloadFile() {
      try {
        this.isLoading = true;
        let _this = this;
        let userToken = getUserData().data.token;

        await axiosIns
            .post(`Trade/GetAllExportExcel`, null, {
              responseType: "blob",
              Authorization: userToken,
              params: {
                isFake: _this.tradesListFilters.isFake,
                market: _this.tradesListFilters.market,
                state: _this.tradesListFilters.state
              }
            })
            .then((resp) => {
              saveAs(resp.data, "trades.xlsx");
            });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    count() {
      return this.tradesListFilters.count;
    },
  },
  components: {
    BButton,
    BBadge,
    BFormGroup,
    vSelect,
    PageWrapper,
    SearchInput,
    CustomTable,
  },
};
</script>

<style scoped></style>
